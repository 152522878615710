import React from 'react'
import '../css/floats.css'
function Floats() {
  return ( 
    <div>
        <div className='row floats-banner-1'>
            <div className='col-12 col-md-5 d-flex justify-content-center align-items-center float-banner-1-img'>
                <img src="images/floats-banner-1-item.png" />
            </div>
            <div className='col-12 col-md-7 floast-banner-1-content'>
                <div>
                    FLOATS
                </div> 
                <div>
                    Float Equipment is used to control the "U tube" effect of fluids down hole. 
                </div>
            </div>
        </div>
        <div className='d-flex flex-column'>
            <div className='row float-section-1 floats-section'>
                <div className='col-12 col-md-6 float-section-content'>
                    <div>FLOAT SHOE</div>
                    <div>
                        A float shoe controls back pressure of cement from the annulus with its valves and are used at the bottom of each size of casing string that guides a smooth running.
                    </div>
                </div>
                <div className='col-12 col-md-6 d-flex flex-column justify-content-center align-items-center'>
                    <div className='d-flex justify-content-center align-items-end'>
                        <img className='float-section-content-img' src='images/float-shoe.svg' />
                    </div>
                </div>
            </div>
            <div className='row float-section-2 floats-section'>
                <div className='col-12 col-md-6 d-flex  flex-column justify-content-center align-items-center'>
                    <div className='d-flex justify-content-center align-items-center '>
                        <img className='float-section-content-img' src='images/float-collar.svg' />
                    </div>
                </div>
                <div className='col-12 col-md-6 float-section-content'>
                    <div>FLOAT COLLAR</div>
                    <div>
                        Float Collars are used in intermediate section of the same size casing to give an extra check point for both controlling of cement flow and back pressure. Provides casing buoyancy during running in and act as a landing pad for cementing plugs.
                    </div>
                </div>
            </div> 
            <div className='row float-section-4 floats-section'>
                <div className='col-12 col-md-6 float-section-content'>
                    <div>PLUGS</div>
                    <div>
                        Plugs are used to separate the cement slurry from other fluids, reducing contamination and maintaining predictable slurry performance. The top plug has a solid body that provides positive indication of contact with the landing collar and bottom plug through an increase in bump pressure. 
                    </div>
                </div>
                <div className='col-12 col-md-6 d-flex justify-content-center align-items-center'>
                    <img className='float-section-content-img' src='images/plugs.svg' />
                </div>
            </div>
            <div className='mb-4'>
                <div className='row float-section-5 floats-section' >
                    <div className='col-12 col-md-6 d-flex justify-content-center align-items-center'>
                        <img className='float-section-content-img' src='images/guide-shoe.svg' />
                    </div>
                    <div className='col-12 col-md-6 float-section-content'>
                        <div>GUIDE SHOE</div>
                        <div>
                            Guide shoe is installed at bottom of casing string. It is used to guide casing to required depth and reinforce lower end of casing. It also guides casing towards hole center and minimizes problems associated with hitting rock ledges or washouts in the wellbore.
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
        
    </div> 
  )
}

export default Floats