import React from 'react'
import '../css/centralizer.css'
function Centralizer() {
  return (
    <div>  
      <div className='row centralizer-banner-1'>
            <div className='col-12 col-md-5 d-flex justify-content-center align-items-center '>
                <img className='centralizer-banner-1-img' src="images/centralizer-banner-1-item.png" />
            </div>
            <div className='col-12 col-md-7 centralizer-banner-1-content'>
                <div>
                    CENTRALIZERS
                </div>
                <div>
                    Centralizers are used to center the casing in the well bore to guarantee a good replacement of the mud by the cement. It provides uniform annular clearance and reduces drag.             
                </div>
            </div>
        </div>
        <div className='d-flex flex-column'>
            <div className='centralizer-section-div'>
                <div className='row centralizer-section'>
                    <div className='col-12 col-md-6 centralizer-section-content'>
                        <div>PREMIUM ONE PIECE</div>
                        <div>
                            One piece construction eliminates bow welding and provides superior strength and reliability. One piece construction provides ultra-high strength, minimal to zero starting and running forces, optimum standoff capability in most challenging well conditions.     
                        </div> 
                    </div>
                    <div className='col-12 col-md-6 d-flex  flex-column justify-content-center align-items-center'>
                        <div className='centralizer-items '>
                            <img src='images/premium-one-new.svg' className='centralizer-info-img-1' />
                        </div>
                    </div>
                </div>
            </div> 
            <div className='centralizer-section-div'> 
                <div className='row centralizer-section'>
                    <div className='col-12 col-md-6 d-flex  flex-column justify-content-center align-items-center'>
                        <img className='centralizer-info-img-2' src='images/standard-bow-spring.svg'/>
                    </div>
                    <div className='col-12 col-md-6 centralizer-section-content'>
                        <div>STANDARD/HINGED BOW SPRING</div>
                        <div>
                            Flexible Bow Centralizers are slightly larger than the wellbore, they can provide complete centralization in vertical and deviated wells. High restoring force, low starting and running force, as benchmarked against API 10D quality standards ensuring better cementation. They are available in Welded and Non-Welded form. The following configurations are available in Hinged, Slip on, Turbolizer, Positive bow and semi rigid designs.
                        </div>
                    </div>
                </div>
            </div>
            <div className='centralizer-section-div' style={{marginBottom:"20px"}}>
                <div className='row centralizer-section'>
                    <div className='col-12 col-md-6 centralizer-section-content'>
                        <div>SOLID/RIGID</div>
                        <div>
                            These Centralizers are close tolerance and slightly under gauge than the wellbore. Provides complete centralization in highly deviated and horizontal wells. The bands provide better strength to withstand high axial force with angled designed vane for better radial strength. Designed for proper rotation during cementing operation and provides maximum flow by area to reduce pressure and to protect formations in open hole. These are available in Heavy duty welded, Casting, Molded, Spiralizer, Pressed hollow vane and Stand off band configurations
                        </div>
                    </div>
                    <div className='col-12 col-md-6 d-flex  flex-column justify-content-center align-items-center'>
                        <img className='centralizer-info-img-3' src='images/solid-rigid.svg' />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Centralizer