import React, {useState} from 'react'
import axios from 'axios'
import { useAuth } from '../utils/auth'
import { useNavigate } from 'react-router-dom'
import { apiUrl } from '../api'
import { useDispatch } from 'react-redux'
import { FLASH_SUCCESS, FLASH_ERROR } from '../constants/actionTypes'
import { Link } from 'react-router-dom'
import '../css/register.css'

function Register() {

    const [userData, setUserData] = useState({username:'', email:'', password:''})
    const [formErrors, setFormErrors] = useState({})
    const dispatch = useDispatch()
    const auth = useAuth()
    const navigate = useNavigate()

    const handleSubmit = async (e) => {
        e.preventDefault()
        setFormErrors(validate(userData))
    }

    const submitRegister = async () => {
        try{
            const config = {
                withCredentials: true,
                headers: {
                  'Content-Type': 'application/json',
                },
              };
            const {data} = await axios.post(`${apiUrl}/register`, userData, config)
            if(data.type === "success"){
                dispatch({type:FLASH_SUCCESS, payload:data.message})
                navigate('/')
            }
            else
                dispatch({type:FLASH_ERROR, payload:data.message})
            
        }
        catch(err){
            dispatch({type:FLASH_ERROR, payload:err.message})
        }
    }

    const validate = (formValues) => {
        let errors = {}
        const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;
        if(formValues.username.length === 0){
            errors = {...errors, username:"Username is required!"}
        }
        if(formValues.email.length === 0){
            errors = {...errors, email:"Email is required!"}
        }
        else if(!emailRegex.test(formValues.email)){
            errors = {...errors, email:"Please enter a valid email!"}
        }
        if(formValues.password.length === 0){
            errors = {...errors, password:"Password is required!"}
        }
        else if(formValues.password.length < 6){
            errors = {...errors, password:"Minimum 6 charachters are required!"}
        }
        if(Object.keys(errors).length === 0){
            submitRegister()
        }
    
        return errors
    }


    return (
    <div>
        <div className='register-page'>
            <div className='register-header'/>
                <div className='register-section'>
                    <form onSubmit={handleSubmit} className='d-flex align-items-center justify-content-center'>
                        {/* <div > */}
                            <div className='d-flex flex-column justify-content-start align-items-center' style={{height:"90vh", paddingTop:"120px"}}>
                                <div className='d-flex flex-column justify-content-between align-items-start'>
                                    <div class=" my-2">
                                        <div class="form-group has-placeholder">
                                            <div className='register-field-label'>Username</div>
                                            <input  className='register-field-input'  type="text" size="30" value={userData.username} onChange={(e) => setUserData({...userData, username: e.target.value})} name="username" placeholder="Username"  />
                                            <div className="contactus-form-error">{formErrors.username?formErrors.username:null}</div>
                                        </div>
                                    </div> 
                                    <div class=" my-2">
                                        <div class="form-group has-placeholder">
                                            <div  className='register-field-label'>Email</div>                    
                                            <input type="text" className='register-field-input' size="30" value={userData.email} onChange={(e) => setUserData({...userData, email: e.target.value})} name="email" placeholder="Email" />
                                            <div className="contactus-form-error">{formErrors.email?formErrors.email:null}</div>                                
                                        </div>
                                    </div>
                                    <div class=" my-2">
                                        <div class="form-group has-placeholder">
                                            <div  className='register-field-label'>Password</div>                    
                                            <input type="password" className='register-field-input' size="30" value={userData.password} onChange={(e) => setUserData({...userData, password: e.target.value})} name="password"  placeholder="Password" />
                                            <div className="contactus-form-error">{formErrors.password?formErrors.password:null}</div>                                
                                        </div>
                                    </div>
                                    <div style={{width:"100%"}} class="d-flex align-items-center justify-content-between my-3">
                                        <button className="register-btn" type="submit">Register</button>
                                        <Link className='login-link' to='/login'>Login</Link>
                                    </div>
                                </div>
                                {/* <div class="col-lg-6 my-2">
                                    <div class="form-group">
                                        New User?<Link to="/register" style={{color:"blue"}}>Sign Up</Link>
                                    </div>
                                </div> */}
                            </div>
                        {/* </div> */}
                        
                </form> 
            </div>
            <img src="images/casewell-login-footer.png" className='register-footer'/>
        </div>

    </div>
  )
}

export default Register