import React from 'react'
import '../css/accessories.css'

function Accessories() {
  return ( 
    <div>
        <div className='d-flex flex-column  flex-md-row' style={{height:"400px"}}>
            <div className='d-flex flex-column justify-content-center accessories-banner-1'>
                <div> 
                    <span>ACCESSORIES</span>
                </div>
                <div>
                    Used to smoothen and simplify the entire casing & cementing process.  These lead to to a better bonding for casing & cementing in the deep surface.
                </div>
            </div>
            <div className='accessories-banner-2'>
                <img src="images/accessories-banner-item.png" />
            </div>
        </div>
        <div className='d-flex flex-column'>   
            <div className='accessories-div'>
                <div className='row accessories-info'>
                    <div className='col-12 col-md-6 mt-md-0 accessories-written-content' >
                        <div className='accessories-info-title'>
                            CEMENT BASKET
                        </div>
                        <div className='accessories-info-content'>
                            Cement baskets run on casing or liners above weak formations that require protection from the hydrostatic pressure generated by the cement column.
                        </div>
                    </div>
                    <div className='col-12 col-md-6 accessories-item-column'>
                        <div className='d-flex align-items-center justify-content-center'>
                            <img src="images/cement-basket.svg" className='me-1 accessories-items-1'/>
                        </div>
                    </div>
                </div>  
            </div>  
            <div className='accessories-div'>
                <div className='row accessories-info'>
                    <div className='col-12 col-md-6 accessories-item-column'>
                        <div className='d-flex align-items-center justify-content-center'>
                            <img src="images/drill-pipe.png" className='me-1 accessories-items-2'/>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 mt-4 mt-md-0 accessories-written-content' >
                        <div className='accessories-info-title'>
                            DRILL PIPE
                        </div>
                        <div className='accessories-info-content'>
                            Drill pipe centralizers are designed to give a minimum amount of drag resistance when passing through the previous casing.
                        </div>
                    </div>
                </div>  
            </div>  
            <div className='accessories-div' style={{marginBottom:"20px"}}>
                <div className='row accessories-info'>
                    <div className='col-12 col-md-6  mt-md-0 accessories-written-content' >
                        <div className='accessories-info-title'>
                            SCRATCHER
                        </div>
                        <div className='accessories-info-content'>
                            Scratchers and wipers help remove excess filter cake & gelled mud from the wellbore.                        
                        </div>
                    </div>
                    <div className='col-12 col-md-6 accessories-item-column'>
                        <div className='d-flex align-items-center justify-content-center'>
                            <img src="images/scratcher.svg" className='me-1 accessories-items-3'/>
                        </div>
                    </div>
                </div>  
            </div>  
        </div>
    </div>
  )
}

export default Accessories