import {CREATE_BLOG,
    LOAD_MORE,
    UPDATE_BLOG,
    DELETE_BLOG,
    FETCH_ALL_BLOGS} from '../constants/actionTypes'


export default (blogs = [], action) => {
    switch(action.type){
        case FETCH_ALL_BLOGS:
            return action.payload;
     
        case LOAD_MORE:     
            console.log([...blogs,...action.payload])     
            return [...blogs,...action.payload];

        case CREATE_BLOG:
            return [...blogs, action.payload];  

        case UPDATE_BLOG:
            return blogs.map((blog) => (blog._id === action.payload._id)?action.payload : blog);  
        
        case DELETE_BLOG:
            return blogs.filter((blog) => blog._id !== action.payload);      
    
        default:
            return blogs;
    }
}