import React, { useEffect } from 'react'
import '../css/home.css'
import {Link} from 'react-router-dom'
import ImageSlider, { Slide } from "react-auto-image-slider";
import 'animate.css'
import SimpleImageSlider from "react-simple-image-slider";
function Home() { 
  const images = [ 
    { url: "images/home-1.jpg" },
    { url: "images/home-2.jpg" },
    { url: "images/home-3.jpg" },
    { url: "images/home-4.jpg" }
  ];
 
  
  return ( 
    <div className='d-flex flex-column home-pafe'>

        <div className='banner'>
          <SimpleImageSlider
            width={"100%"}
            height={400}
            style={{backgroundPosition:"center"}}
            images={images}
            showBullets={true}
            showNavs={true}
            autoPlay={true} 
            slideDuration={3}
          />

          <div className='inner-banner'>
            <div><span style={{borderBottom:"2px solid white"}}>CEMENTING DEEP TRUST</span></div>
            <div>Global Experts in Cementing Products for Oil & Gas Industry</div>
            <Link to='/contactus' id='contact-btn'>Contact Us</Link>
          </div>
        </div>

      <div className='d-flex justify-content-center' style={{position:"relative", backgroundColor:'#F2F2F2', padding:"50px 0", marginBottom:"20px"}}>
        <div className='row ' style={{width:"100%"}}>
          <div className='col-12 col-md-6 home-banner-2-item' data-aos="fade-down-right" data-aos-delay="500">
            <div className='second-banner-icon-img'>
              <img  src="images/home-info-1-new.png" />
            </div>
            <div>
              One of the Largest API Certified <br /> 
              manufacturer of Centralizers <br />
              in Asia Pacific
            </div>
            <div  className='second-banner-border'>
              <div className='second-banner-border-line'/>
            </div> 
          </div>
          <div className='col-12 col-md-6 home-banner-2-item' data-aos="fade-down-left" data-aos-delay="1500"><div className='second-banner-icon-img'>
            <img className='second-banner-icon-img' src="images/home-info-2-new.png" /></div>
            <div>
              Global supplier to <br />
              25+ countries 
            </div>
            <div  className='second-banner-border'>
              <div className='second-banner-border-line'/>
            </div> 
          </div>
          <div className='col-12 col-md-6 home-banner-2-item' data-aos="fade-up-right" data-aos-delay="1000"><div className='second-banner-icon-img'>
            <img className='second-banner-icon-img' src="images/home-info-3-new.png" /></div>
            <div>
              Ranked amongst the Top <br /> 5 Global Centralizer <br /> Producers 
            </div>
            <div  className='second-banner-border'>
              <div className='second-banner-border-line'/>
            </div> 
          </div> 
          <div className='col-12 col-md-6 home-banner-2-item' data-aos="fade-up-left" data-aos-delay="2000"><div className='second-banner-icon-img'>
            <img className='second-banner-icon-img' src="images/home-info-4-new.png" /></div>
            <div>
              Products operational <br />
              in 5000+  wells <br /> worldwide
            </div>
            <div  className='second-banner-border'>
              <div className='second-banner-border-line'/>
            </div> 
          </div>
        </div>
        </div>
    </div>
  )
}

export default Home