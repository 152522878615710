import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { deleteBlog } from '../actions/blogs';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../utils/auth';
import '../css/blogDetail.css'
import axios from 'axios';
import { apiUrl } from '../api';
import { Spinner } from './Spinner';
function BlogDetailNew() {

    const {blogId} = useParams() 
    const auth = useAuth()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [blogLimit, setBlogLimit] = useState(6)
    const [blog, setBlog] = useState()
    // const allBlogs = useSelector((state) => state.blogs)

    // const blog = allBlogs.filter((blog) => blog._id === blogId)[0]
    // const relatedBlogs = allBlogs.filter((reblog) => reblog.category === blog.category)
    // console.log(blog)

    const config = {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      };

    const getBlogDetails = async () => {
        const {data} = await axios.get(`${apiUrl}/blogs/details/${blogId}`,config)
        setBlog(data)
    }
  

    const handleDelete = async (e) => {
        e.preventDefault()
        dispatch(deleteBlog(blog))
        navigate('/')
      }


    useEffect(() => {
        if(!blog){
            getBlogDetails()           
        }

        
        if(document.getElementById('blogContainer')&&blog){
            const blogContainer =  document.getElementById('blogContainer')
            blogContainer.innerHTML = blog.body
            console.log(blogContainer.innerHTML)

            if(document.getElementById('blogDate')){
                const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
                const str = blog.date;
                const date = new Date(str)
                const blogDate =  document.getElementById('blogDate')
                blogDate.innerHTML = `${date.getDate()} ${month[date.getMonth()]}, ${date.getFullYear()}`
            }
        }

    }) 
  return ( 

    <div className='d-flex flex-column'>
        {blog?
        //style={{background:`no-repeat center/cover url("uploads/${blog.image}")`}} 
            <>
                {/* <div className='blog-banner' style={{background:`no-repeat center/cover url("${process.env.PUBLIC_URL}/uploads/${blog.image}")`}}> */}
                <div className='blog-banner' style={{background:`no-repeat center/cover url("${apiUrl}/uploads/${blog.image}")`}}>
                    <div className='blog-banner-content'>
                        <div className='blog-banner-category'>{blog.category}</div>
                        <div className='blog-banner-title'><u>{blog.title}</u></div>
                    </div>
                </div>
                 
                <div className='d-flex justify-content-between'>
                    <div className='blog-username'>By&nbsp;<span style={{fontStyle:"italic"}}>{blog.username}</span></div>
                    <div className='blog-date' id="blogDate" ></div>
                </div>
                <div style={{height:"5px", background:"#D9D9D9"}}/>
                <div id="blogContainer"> </div>  
                {/* <div className='d-flex flex-column' style={{padding:"0 36px"}}>
                    <div style={{fontWeight:"600", fontSize:"24px", color:"#CE0002", margin:"0 0 14px 0"}}>Related Posts</div>
                    <div className='d-flex'>
                    {
                        relatedBlogs.map((reblog, index) => {
                            console.log(index)
                            if(index < 3 && (reblog._id !== blog._id))
                                return <Link to={`/blogs/details/${reblog._id}`} state={{id: reblog._id}}>
                                            <div className='related-post'>
                                                <img src={reblog.image} />
                                                <div className='related-post-title'>{reblog.title}</div>
                                            </div> 
                                        </Link>
                        })
                    }

                    </div>
                    
                </div> */}
                
                { 
                                    
                    (auth.loggedUser&&((auth.loggedUser._id === process.env.REACT_APP_ADMIN)||(blog.user?blog.user._id === auth.loggedUser._id: false)))?
                    <div className="d-flex justify-content-end">
                        <Link to={`/blogs/editBlogs/${blogId}`} className='btn btn-info' state={{blog}}>Edit Blog</Link>

                        <form onSubmit={handleDelete}>
                            <button  className='ms-4 btn btn-danger' state={{id: blog._id}}>Delete Blog</button>
                        </form>
                    </div>
                    :null
                                
                }

            </>
        :<div className='d-flex justify-content-center al my-5' style={{height:`662.26px`, paddingTop:"250px"}}><Spinner /></div>
        }
        
    </div>
    
  )
}

export default BlogDetailNew