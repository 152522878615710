import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getBlogs, loadMore } from '../actions/blogs'
import { Link } from 'react-router-dom'
import { useAuth } from '../utils/auth'
import styled from 'styled-components'
import '../css/blog.css'
import { Spinner } from './Spinner'
import { apiUrl } from '../api'
  
function BlogNew() { 
    const dispatch = useDispatch()
	const auth = useAuth()
	const allBlogs = useSelector((state) => state.blogs)
    let n = allBlogs.length
    const [filter, setFilter] = useState('All')
    const [blogIndex, setBlogIndex] = useState(0)
    const [flag, setFlag] = useState(true)
    const categories = ['All', 'Centralizer', 'Cementing', 'Floats', 'Oil & Gas']

    const loadMore = () => {
        setBlogIndex(blogIndex+6)
    }
	useEffect(() => {
		dispatch(getBlogs(blogIndex))
        if(blogIndex >allBlogs.length){
            setFlag(false) 
            console.log("flag: ",flag)
        }
	}, [dispatch,blogIndex])

  return ( 
    <div className='d-flex flex-column'>
            {
                
                allBlogs.length?
                <>  
                    <div className='first-blog' style={{background:`no-repeat center/cover url("${apiUrl}/uploads/${allBlogs[0].image}")`}}>
                        <div className='first-blog-content'>
                        <div className='first-blog-category'>{allBlogs[0].category}</div>
                        <div className='first-blog-title'><u>{allBlogs[0].title}</u></div>
                        <div style={{width:"100%"}} >
                            <Link to={`/blogs/details/${allBlogs[0]._id}`} state={{id: allBlogs[0]._id}}  >
                                <button className="first-blog-readMore">
                                    Read More
                                </button>
                            </Link>
                            </div>
                        </div>
                    </div> 
                    {/* <div className='first-blog' style={{background:`no-repeat center/cover url("${apiUrl}/uploads/${allBlogs[n-1].image}")`}}>
                        <div className='first-blog-content'>
                        <div className='first-blog-category'>{allBlogs[n-1].category}</div>
                        <div className='first-blog-title'><u>{allBlogs[n-1].title}</u></div>
                        <div style={{width:"100%"}} >
                            <Link to={`/blogs/details/${allBlogs[n-1]._id}`} state={{id: allBlogs[n-1]._id}}  >
                                <button className="first-blog-readMore">
                                    Read More
                                </button>
                            </Link>
                            </div>
                        </div>
                    </div>  */}
                    <div className='d-flex ps-5 flex-wrap categories'>
                        {
                            categories.map((category) => {
                                return <div className='categories-name'  onClick={(e) => {
                                    setFilter(category)
                                    // console.log(filter)
                                }}>{category}</div>
                            })
                        }
                    </div> 
                    <div className='row'>
                        {
                             allBlogs.map((blog, index) => {
                                if(index!=0 && (filter === 'All' || blog.category===filter))
                                return  <div className='col-12 col-sm-6 col-xl-4 '>
                                            <div className='d-flex flex-column align-items-start justify-content-between blogs-column'>
                                                {/* {blog.image?<div style={{width:"100%"}}><img src={`uploads/${blog.image}`} /></div>:null} */}
                                                {blog.image?<div style={{width:"100%"}}><img src={`${apiUrl}/uploads/${blog.image}`} /></div>:null}
                                                <div className='blog-category'>{blog.category}</div>
                                                <div className='blog-title'>{blog.title}</div>
                                                <div className='blog-summary'>{blog.summary}</div>
                                                <Link to={`/blogs/details/${blog._id}`} state={{id: blog._id}}>
                                                    <button className="readMore">
                                                        Read More
                                                    </button>
                                                </Link>
                                            </div> 
                                        </div> 
                            })
                            // allBlogs.slice(0).reverse().map((blog, index) => {
                            //     if(index!=0 && (filter === 'All' || blog.category===filter))
                            //     return  <div className='col-12 col-sm-6 col-xl-4 '>
                            //                 <div className='d-flex flex-column align-items-start justify-content-between blogs-column'>
                            //                     {/* {blog.image?<div style={{width:"100%"}}><img src={`uploads/${blog.image}`} /></div>:null} */}
                            //                     {blog.image?<div style={{width:"100%"}}><img src={`${apiUrl}/uploads/${blog.image}`} /></div>:null}
                            //                     <div className='blog-category'>{blog.category}</div>
                            //                     <div className='blog-title'>{blog.title}</div>
                            //                     <div className='blog-summary'>{blog.summary}</div>
                            //                     <Link to={`/blogs/details/${blog._id}`} state={{id: blog._id}}>
                            //                         <button className="readMore">
                            //                             Read More
                            //                         </button>
                            //                     </Link>
                            //                 </div> 
                            //             </div> 
                            // })
                        }
                    </div>
                </> 
                :<div className='d-flex justify-content-center al my-5' style={{height:`662.26px`, paddingTop:"250px"}}><Spinner /></div>
            }
        {
        flag?
            <div className='d-flex justify-content-center ' style={{cursor:"pointer", margin:"80px 0 30px"}}>
                <div onClick={loadMore} id="load-more">
                    <div>Load More</div>
                </div>
            </div>
        :<div className='d-flex justify-content-center' style={{margin:"30px 0"}}>--------------------End of Page--------------------</div>
        }
      


    </div>
  )
}



export default BlogNew