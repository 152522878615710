import React from 'react'
import '../css/capabilities.css'

function Capabilities() {
  return ( 
    <div>  
        <div className='capabilities-banner'>
            <div className='capabilities-inner-banner'>
                <div><span>OUR CAPABILITIES</span></div>
                <div>
                    We are Committed to Engineer Excellence through our quality-centric & comprehensive manufacturing process, and a priority toward customer satisfaction.
                </div>
            </div>
        </div>
        <div className='d-flex justify-content-center flex-column flex-sm-row flex-wrap capabilites-container' >
                <div className='capabilities-column'>
                    <img src='images/capabilities-1-new.png' className='capabilities-column-img'/>
                    <div className='capabilities-column-heading'>STATE OF THE ART TECHNOLOGY</div>
                    <div className='capabilities-column-data'>Our products are designed, manufactured, and tested at our world-class facility. It is equipped with a Robotic welding machine and, Automated & Environment-friendly Heat Treatment facilities. </div>
                </div>  
                <div className='capabilities-column'>
                    <img src='images/capabilities-2-new.png' className='capabilities-column-img'/>
                    <div className='capabilities-column-heading'>TOP QUALITY STANDARDS</div>
                    <div className='capabilities-column-data'>Our Quality Management System is designed as per API Spec Q1 and ISO 9001-2015, EMS & OHSAS 14001. All our products meet or exceed applicable API standards. </div>
                </div>
                <div className='capabilities-column'>
                    <img src='images/capabilities-3-new.png' className='capabilities-column-img'/>
                    <div className='capabilities-column-heading'>WORLDWIDE DISTRIBUTION</div>
                    <div className='capabilities-column-data'>Casewell is one of the largest manufacturers of quality Centralizers, Float equipment and accessories in Asia Pacific. With distribution across 25 countries, we have been enhancing our capabilities to serve our customers worldwide.</div>
                </div>
                <div className='capabilities-column'>
                    <img src='images/capabilities-4-new.png' className='capabilities-column-img'/>
                    <div className='capabilities-column-heading'>SUPPLY CHAIN MANAGEMENT</div>
                    <div className='capabilities-column-data'>Our team of professionals works around the clock to procure and manage the raw materials to manufacture and transport the goods at our customer’s disposal. For faster turnaround time, we stock our products at various locations across the world.</div>
                </div>
                <div className='capabilities-column'>
                    <img src='images/capabilities-5-new.png' className='capabilities-column-img'/>
                    <div className='capabilities-column-heading'>RESEARCH & DEVELOPMENT</div>
                    <div className='capabilities-column-data'>Our R&D division consistently strives to provide our customers with top-notch products using cutting-edge technology and simulation techniques. We continuously work towards optimizing product performance to meet dynamic well-cementing challenges.</div>
                </div>
            </div>
    </div>
  )

  {/* OLD DESIGN */}

        {/* <div className='capabilities-content'>
            <div className='row'>
                <div className='col-12 col-md-6 border-right border-bottom capabilities-column'>
                    <div className='capabilities-upper-content'>
                        <img src="images/capabilities-1.png" className='capabilities-column-logo'/>
                        <div className='d-flex flex-column'>
                            <div className='capabilites-column-heading'>STATE OF THE ART TECHNOLOGY</div>
                            <div className='capabilites-column-data'>Our products are designed, manufactured, and tested at our world-class facility. It is equipped with a Robotic machine and, Automated & Environment-friendly Heat Treatment facilities. We use Acrylic Cathode Electrode Deposition Paint that is Eco Friendly and H2S resistant. It provides enhanced flexibility and corrosion resistance to our products.</div>
                        </div>
                    </div>
                    <div className='d-flex '>
                        <img src="images/capabilities-7.png" className='capabilities-footer-logo'/>
                    </div>
                </div>
                <div className='col-12 col-md-6 border-bottom ps-2 capabilities-column'>
                    <div className='ms-3 capabilities-upper-content'>
                        <img src="images/capabilities-2.png" className='capabilities-column-logo'/>
                        <div className='d-flex flex-column'>
                            <div className='capabilites-column-heading'>RESEARCH & DEVELOPMENT</div>
                            <div className='capabilites-column-data'>Our R&D division consistently strives to provide our customers with top-notch products using cutting-edge technology and simulation techniques. We are continuously working towards optimizing product performance to meet customers' dynamic well-cementing challenges.</div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-start justify-content-md-end' style={{width:"100%"}}>
                        <img src="images/capabilities-7.png" className='capabilities-footer-logo'/>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-12 col-md-6 border-right border-bottom capabilities-column'>
                    <div className='capabilities-upper-content'>
                        <img src="images/capabilities-3.png" className='capabilities-column-logo'/>
                        <div className='d-flex flex-column'>
                            <div className='capabilites-column-heading'>TOP QUALITY STANDARDS</div>
                            <div className='capabilites-column-data'>Our Quality Management System is designed as per API Spec Q1 and ISO 9001-2015, EMS & OHSAS 14001. All our products meet or exceed applicable API standards.</div>
                        </div>
                    </div>
                    <div className='d-flex '>
                        <img src="images/capabilities-7.png" className='capabilities-footer-logo'/>
                    </div>
                </div>
                <div className='col-12 col-md-6 border-bottom ps-2 capabilities-column'>
                    <div className='ms-3 capabilities-upper-content'>
                        <img src="images/capabilities-4.png" className='capabilities-column-logo'/>
                        <div className='d-flex flex-column'>
                            <div className='capabilites-column-heading'>ZERO PRODUCT FAILURE</div>
                            <div className='capabilites-column-data'>We have always believed in building products that withstand the environment not only today but can bear with the climate changes tomorrow without any engineering fails. None of our products has ever failed. We give our clients the reassurance they need when buying industrial products.</div>
                        </div>
                    </div>
                    <div className='d-flex justify-content-start justify-content-md-end' style={{width:"100%"}}>
                        <img src="images/capabilities-7.png" className='capabilities-footer-logo'/>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-12 col-md-6 border-right capabilities-column' style={{borderBottom:"1px solid black"}}>
                    <div className='capabilities-upper-content'>
                        <img src="images/capabilities-5.png" className='capabilities-column-logo'/>
                        <div className='d-flex flex-column'>
                            <div className='capabilites-column-heading'>SUPPLY CHAIN MANAGEMENT</div>
                            <div className='capabilites-column-data'>Our Supply Chain Management System enables us to deliver your orders to your desired location right on time. Our team of professionals works around the clock to procure and manage the raw materials to manufacture and transport the goods at our customer’s disposal. For faster turnaround time, we stock our products at various locations worldwide, including North America, Europe, and the Middle East.</div>
                        </div>
                    </div>
                    <div className='d-flex '>
                        <img src="images/capabilities-7.png" className='capabilities-footer-logo'/>
                    </div>
                </div>
                <div className='col-12 col-md-6 ps-2 border-bottom capabilities-column'>
                    <div className='ms-3 capabilities-upper-content'>
                        <img src="images/capabilities-6.png" className='capabilities-column-logo'/>
                        <div className='d-flex flex-column'>
                            <div className='capabilites-column-heading'>WORLDWIDE DISTRIBUTION</div>
                            <div className='capabilites-column-data'>Casewell is Asia Pacific’s largest quality centralizers, float equipment, and accessories producer. With distribution across 25 countries and warehouses across 3 continents, we have been emphatically increasing our ability to serve customers worldwide.</div>
                        </div>
                    </div>
                    <div className='d-flex  justify-content-start justify-content-md-end' style={{width:"100%"}}>
                        <img src="images/capabilities-7.png" className='capabilities-footer-logo'/>
                    </div>
                </div>
            </div>
        </div> */}
}

export default Capabilities