import React, { useEffect } from 'react'
import '../css/notfound.css'

function NotFound() {
  useEffect(() => {
  })
  return (
    <div className='notfound-page' id="df">
      The page you are trying to visit doesn't exist!
    </div>
  )
}

export default NotFound