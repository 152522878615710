import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getBlogs } from '../actions/blogs'
import { Link } from 'react-router-dom'
import { useAuth } from '../utils/auth'
import styled from 'styled-components'

function Blog() {

	const dispatch = useDispatch()
	const auth = useAuth()
	const blogs = useSelector((state) => state.blogs)

	console.log(blogs)

	useEffect(() => {
		dispatch(getBlogs())
	}, [dispatch])
	

  	return (
    <div>
			<section class="term-and-conditon-page blog-toptitle">
				<div  class="container d-flex justify-content-between">
					<h1><span>OUR</span> BLOG</h1>
					<Link style={{ color:"#9a0000"}} to='/blogs/createBlog'><h1><span>CREATE</span> BLOG</h1></Link>
					<Link style={{ color:"#9a0000"}}to='/userBlogs'><h1><span>MY</span> BLOGS</h1></Link>
				</div>
			</section>
			
 
			<section class="blog-page-first-top">
				<div class="container">
					<div class="row">
						<div class="col-md-7">
							<h6>Latest</h6>
							<h1>SuperEasy Ways To Learn <br /> <span>Everything About Industry</span></h1>
							<p>Qui nunc nobis videntur parum clari, sollemnes in futurum putamus parum claram legere.</p>
							<a href="">Read More</a>
						</div>
						<div class="col-md-5">
						<img src="images/blog-top-right.png" class="blog-righttop-img" alt=""/>
						</div>
					</div>
				</div>
			</section>
			
			
			<section class="bloglisting">
				<div class="container">
				<div class="row">
					{
						blogs.length?
							blogs.slice(0).reverse().map((blog) => {
								const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
								const str = blog.date;
								const date = new Date(str)
								return (
									<div class="col-sm-6 col-md-6 col-lg-4">
											<div class="blog-grid">
												<a href="">
													<img src={blog.image} alt=""/>
												</a>
												<div class="blog-grid-content">
													<h2>{blog.title}</h2>
													<ul class="admin-post">
														<li>{`${date.getDate()} ${month[date.getMonth()]}, ${date.getFullYear()}`}</li>
														<li>By {blog.username}</li>
														<li><Link to={`/blogs/details/${blog._id}`} state={{id: blog._id}}>Read More <i class="fas fa-right-long fa-fw"></i></Link></li>
													</ul>
												</div>
											</div>
									</div>
							)}):<div className='d-flex justify-content-center my-5'><Spinner /></div>	
					}
									
					</div>
					
					
					<div class="row d-flex align-items justify-content-center">
					<div class="col-md-12 text-center"><a href="" class="l-morebtn">LOAD MORE</a></div>
					</div>		
					
					
				</div>
			</section>
 
			<section class="red-bg-seciton" style={{padding: "0px 0 0px 0"}}></section>

    </div>
  )
}

const Spinner = styled.div`
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #9A0000 transparent #9A0000 transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;

    @keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
`



export default Blog