import React from 'react'
import '../css/aboutUs.css'
function AboutUs() {
  return (
    <div className='aboutUs-page'>
        <div className='row aboutus-banner'>
            <div className='col-12 col-md-6 d-flex align-items-center justify-content-center'>
                <iframe width="480" height="270" src="https://www.youtube.com/embed/Wu9Gt4xmjU4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div className= 'col-12 col-md-6 d-flex flex-column justify-content-center align-items-center aboutus-banner-content'>
                <div>
                    About Us
                </div>
                <div> 
                    Established in 2006 with the vision to be recognized as a discerning producer of superior grade tools for the Oil & Gas industry. Today we are the largest API & ISO Certified manufacturer of Centralizers in Asia Pacific.
                    {/* Largest API Certified manufacturer of <br/> Centralizers in Asia Pacific */}
                </div>
            </div>
        </div>
        {/* <div className='aboutus-mid-section'>
            Established in 2006 with the vision to be recognized as a discerning producer of superior grade tools for the Oil & Gas industry. Today we are the largest API & ISO Certified manufacturer of Centralizers in Asia Pacific.
        </div> */}
        <div className='aboutus-section'>
            <div className='aboutus-section-title'><span>Core Values</span></div>
            <div className='d-flex flex-column flex-md-row flex-wrap justify-content-center align-items-center '>
                <div className='aboutus-coreValues'>                       
                        <img src='images/aboutUs-item-1.png' />
                        <div className='aboutus-coreValues-title'>Integrity</div>
                        <div className='aboutus-coreValues-info'>
                            Ethics and Integrity are hallmarks of our organization and are ingrained in our DNA
                        </div>   
                </div> 
                <div className='aboutus-coreValues'> 
                    <img src='images/aboutUs-item-2.png' />
                    <div className='aboutus-coreValues-title'>Collaboration</div>
                    <div className='aboutus-coreValues-info'>
                        We work hand-in-hand with our customers and suppliers to achieve our mutual success
                    </div>
                </div>
                <div className='aboutus-coreValues'>
                    <img src='images/aboutUs-item-3.png' />
                    <div className='aboutus-coreValues-title'>Intellectual Curiosity</div>
                    <div className='aboutus-coreValues-info'>
                        We strive to innovate and build top-notch products
                    </div>
                </div> 
                <div className='aboutus-coreValues'>                 
                    <img src='images/aboutUs-item-4.png' />
                    <div className='aboutus-coreValues-title'>Courage</div>
                    <div className='aboutus-coreValues-info'>
                        We dare to explore the unknown and exceed new challenges
                    </div>
                </div>
                <div className='aboutus-coreValues'>
                    <img src='images/aboutUs-item-5.png' />
                    <div className='aboutus-coreValues-title'>Safety</div>
                    <div className='aboutus-coreValues-info'>
                        We follow the highest safety standards to ensure that each member can work with us without worrying about it.
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AboutUs