import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteUser, getUsers, updateUsers } from '../actions/users'
import {useNavigate} from "react-router-dom"
import '../css/adminUsers.css'
import { Spinner } from './Spinner'
 
function AdminUsers() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const users = useSelector((state) => state.users)
    console.log(users)
    const updateAprroval = (user) => {
        dispatch(updateUsers(user))
    }

    const userDelete = (user) => {
        dispatch(deleteUser(user))
    }

    useEffect(() => {
        dispatch(getUsers())

    },[dispatch])

  return (
    <div className='adminuserscontainer'>
        <div className='row m-0 d-flex justify-content-center' >
            <div className='col-1 users-headings'>
                S.No.
            </div>
            <div className='col-3 users-headings'>
                Name
            </div>
            <div className='col-2 users-headings'>
                Status
            </div>
            <div className='col-2 users-headings'>
                Approve
            </div>
            <div className='col-2 users-headings' style={{borderRight:"0"}}>
                Delete
            </div>
        </div>
        {
            users.length?
            users.slice(0).reverse().map((user, index) => {
            if(user.username !== 'admin')
                return(
                    <div className='row mx-0 d-flex justify-content-center'>
                        <div className='col-1 users-info'>
                            {index+1}
                        </div>
                        <div className='col-3 users-info'>
                            {user.username}
                        </div>
                        <div className='col-2 users-info'>
                            {
                                user.approved?
                                'Approved':"Not Approved"
                            }
                        </div>
                        <div className='col-2 users-info'>
                            <button className={`user-btn ${user.approved?'ngt':'pst'}-btn`} onClick={() => updateAprroval(user)}>{`${user.approved?'Unapprove':'Approve'}`}</button>
                        </div>
                        <div className='col-2 users-info' style={{borderRight:"0"}}>
                            <button className='user-btn ngt-btn' onClick={() => userDelete(user)}>Delete</button>
                        </div>
                    </div>
                )}):<div className='d-flex justify-content-center al my-5' style={{height:`662.26px`, paddingTop:"250px"}}><Spinner /></div>
        }

      
    </div>
    

    // <div>
    //     <table>
    //         <tr>
    //             <td className='users-headings'>S.No.</td>
    //             <td className='users-headings'>Name</td>
    //             <td className='users-headings'>Status</td>
    //             <td className='users-headings'>Approve</td>
    //             <td className='users-headings'>Delete</td>
    //         </tr>
    //     </table>
    // </div>

    



    // <div className='m-5'>
    //   <div className='row'>
    //         <div className='col-2 border border-dark'>
    //             Username
    //         </div>
    //         <div className='col-4 border border-dark'>
    //             Email
    //         </div>
    //         <div className='col-2 border border-dark'>
    //             Approve Status
    //         </div>
    //         <div className='col-2 border border-dark'>
    //             Approval Status
    //         </div>
    //         <div className='col-2 border border-dark'>
    //             Delete User
    //         </div>
    //     </div>
    //     {
    //         users.length?
    //         users.map((user) => {
    //            if(user.username !== 'admin')
    //            return(
    //             <div className='row'>
    //                 <div className='col-2 border border-dark'>
    //                     {user.username}
    //                 </div>
    //                 <div className='col-4 border border-dark'>
    //                     {user.email}
    //                 </div>
    //                 <div className='col-2 border border-dark'>
    //                     {
    //                         user.approved?
    //                         'Approved':"Not Approved"
    //                     }
    //                 </div>
    //                 <div className='col-2  d-flex justify-content-center border border-dark'>
    //                     <button className={`btn btn-${user.approved?'danger':'success'}`} onClick={() => updateAprroval(user)}>{`${user.approved?'Unapprove':'Approve'}`}</button>
    //                 </div>
    //                 <div className='col-2 d-flex justify-content-center border border-dark'>
    //                     <button className='btn btn-danger' onClick={() => userDelete(user)}>Delete</button>
    //                 </div>
    //             </div>
    //         )}):null
    //     }
    // </div>
  )
}

export default AdminUsers