import React, {useEffect, useState} from 'react'
import FileBase from 'react-file-base64'
import { createBlog } from '../actions/blogs' 
import axios from "axios"
import { useDispatch } from 'react-redux'
import RichTextEditor from './RichTextEditor'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../utils/auth'
import '../css/CreateAndEditBlog.css'
 

function CreateBlog() {

    const [blogBody, setBlogBody] = useState("")
    const [blogData, setBlogData] = useState({ title:'', image: '', category:'', summary:'' ,body:''})
    const [formErrors, setFormErrors] = useState({})
    const auth = useAuth()
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault()
        setFormErrors(blogValidation(blogData))

    }

    const blogValidation = (blog) => {
        let errors = {}

        if(blog.title.length === 0){
            errors = {...errors, title:'Title is empty!'}
        }
        if(blog.image.length === 0){
            errors = {...errors, image:'Image is not selected!'}
        }
        if(blog.category.length === 0){
            errors = {...errors, category:'Category is not selected!'}
        }
        if(blog.summary.length === 0){
            errors = {...errors, summary:'Summary is empty!'}
        }
        if(blog.body.length === 0){
            errors = {...errors, body:'Blog content is empty!'}
        }

        if(Object.keys(errors).length === 0){
            submitBlog()
        }
        
        return errors
    }

    const submitBlog = () => {
        const formData = new FormData();
        formData.append('title', blogData.title)
        formData.append('image', blogData.image)
        formData.append('category', blogData.category)
        formData.append('summary', blogData.summary)
        formData.append('body', blogData.body)
        formData.append('author', auth.loggedUser._id)

        dispatch(createBlog(formData))    
        navigate('/')
    }
    useEffect(() => {
        setBlogData({...blogData, body:blogBody})

    }, [blogBody])
  return (
    <div className='blog-content mb-5' style={{overflow:"auto"}}>
        <div className='row d-flex justify-content-center'>
            <div className='col-md-9'>
                <div className='d-flex flex-column justify-content-top align-items-top m-5'  style={{height:"90vh"}}>
                    <div className='d-flex align-items-center' style={{background: "rgb(2,0,36)",
                            background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 0%, rgba(5,90,174,1) 0%, rgba(5,84,170,1) 0%, rgba(3,125,196,1) 0%, rgba(4,109,186,1) 0%, rgba(154,0,0,1) 0%, rgba(255,255,255,1) 100%)"}}>
                        <h2 className='m-1 ms-2' style={{color:"white"}}>
                            Create Blog
                        </h2>
                    </div>
                    <form onSubmit={handleSubmit} enctype='multipart/form-data' validate>
                        <div>    
                            <div className='row my-5  '>
                                <div className='col-3'>
                                    Choose Banner: 
                                </div>
                                <div className="col-9 ">
                                    {/* <FileBase type="file" multiple={false} onDone={({base64}) => setBlogData({...blogData, image: base64})} /> */}
                                    <input type="file" onChange={(e) => setBlogData({...blogData, image: e.target.files[0]})} />
                                    <div className="blog-form-error">{formErrors.image?formErrors.image:null}</div>  
                                </div>
                            </div>
                            <div className='row my-5'>
                                <div className='col-3'>
                                        Enter Title: 
                                </div>
                                <div className='col-9'>
                                    <input className='form-control' type="text" placeholder='Enter title' onChange={(e) => setBlogData({...blogData, title: e.target.value})}/>
                                    <div className="blog-form-error">{formErrors.title?formErrors.title:null}</div>  
                                </div>
                            </div> 
                            <div className='row my-5'>
                                <div className='col-3'>
                                        Choose Category: 
                                </div>
                                <div className='col-9'>
                                    <select onChange={(e) => setBlogData({...blogData, category: e.target.value})} class="form-select" aria-label="Default select example">
                                        <option value="none" selected disabled>Open this select menu</option>
                                        <option value="Centralizer">Centralizer</option>
                                        <option value="Cementing">Cementing</option>
                                        <option value="Floats">Floats</option>
                                        <option value="Oil & Gas">Oil & Gas</option>
                                    </select>
                                    <div className="blog-form-error">{formErrors.category?formErrors.category:null}</div>  
                                </div>
                            </div>
                            <div className='row my-5'>
                                <div className='col-3'>
                                        Write Summary: 
                                </div>
                                <div className='col-9'>
                                    <textarea className='form-control' placeholder='Write summary' onChange={(e) => setBlogData({...blogData, summary: e.target.value})} />
                                    <div className="blog-form-error">{formErrors.summary?formErrors.summary:null}</div>  
                                </div>
                            </div>
                            <div className='my-3'>
                                <label className='mb-3'>
                                    Write Blog Content: 
                                </label>
                                <RichTextEditor className="editorPanel" initialValue={""} setBlogBody={setBlogBody}/>
                                <div className="blog-form-error">{formErrors.body?formErrors.body:null}</div>  
                            </div>
                            <div className='my-5'>
                                {/* <button className='submit-btn' onClick={handleSubmit}>Submit</button> */}
                                <button type="submit" className='submit-btn'>Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CreateBlog