import React from 'react'
import '../css/csr.css'
function Csr() {   
  return (  
    <div className='csr-page'>
        <div className='row csr-banner'>
            <div className='col-12 col-md-4 d-flex justify-content-center justify-content-md-end align-items-center '>
                <img className='csr-banner-img'  src="images/csr-banner-item-new.png" />
            </div>
            <div className='col-12  col-md-8 csr-banner-content' >
                <div>
                    <span className='csr-banner-title-underline'>CORPORATE SOCIAL RESPONSIBILITY</span> 
                </div>
                <div>
                    We are committed to achieving sustainable development goals and enhancing the quality of life of people who belong to marginalized societies.
                </div>
            </div>
        </div>
        <div className='csr-section'>
            <div className='row csr-info' >
              <div className='col-12 col-md-8 csr-written-content' >
                  Casewell has contributed to the improvements of its social and environmental responsibility. We have initiated and funded many social and environmental-related CSR programs, development schemes, projects, and activities. We have also launched initiatives that include broader issues- such as biodiversity and climate change. Our pollution-related initiatives are spreading awareness about the environmental risks of oil and drilling operations and how we can mitigate these risks.              
              </div>
              <div className='col-12 col-md-4 csr-item-column'>
                  <img src="images/csr-section-item-1-new.png" />
                  <div className='csr-section-img-title'>ENVIRONMENT</div>
              </div>
            </div>
            <div className='row csr-info' >
              <div className='col-12 col-md-4 csr-item-column'>
                  <img src="images/csr-section-item-2-new.png" />
                  <div className='csr-section-img-title'>EDUCATION</div>
              </div>
              <div className='col-12 col-md-8 csr-written-content' >
                Casewell is committed to societal development, and one of the pillars of societal development is ‘Education.’ Casewell is serving communities by providing free education to underprivileged children. Our skill-enhancing initiatives and digital literacy programs are curated for young minds who belong to migrant labor families. We have different projects for different age-group to encourage them to build life skills. Our initiative, ‘Read Young India,’ is specially launched to build reading and writing skills in children of standards three to four.              </div>
              </div>
            <div className='row csr-info mb-4' style={{marginBottom:"20px !important"}}>
              <div className='col-12 col-md-8 csr-written-content' >
                Hunger and undernutrition are the biggest challenges looming in front of our society. Casewell CSR initiatives are extensively contributing to feeding the underprivileged. Casewell has launched many meal schemes to improve nutritional levels among impoverished children and adults. We prepare and serve quality food under our various meal schemes to support and contribute to feeding the underprivileged and malnourished population.
              </div>
              <div className='col-12 col-md-4 csr-item-column'>
                  <img src="images/csr-section-item-3-new.png" />
                  <div className='csr-section-img-title'>FEEDING</div>
              </div>
            </div>
        </div>
    </div>
  )
}

export default Csr