import React, {useState} from 'react'
import axios from 'axios'
import { useNavigate, useLocation} from 'react-router-dom'
import { useAuth } from '../utils/auth'
import { Link } from 'react-router-dom'
import { apiUrl } from '../api'
import { useDispatch } from 'react-redux'
import { FLASH_SUCCESS, FLASH_ERROR } from '../constants/actionTypes'
import '../css/login.css'

function Login() { 

    const [logUserData, setlogUserData] = useState({username:'', password:''})

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const auth = useAuth()

    const handleSubmit = async (e) => {
        e.preventDefault()
        try{
            const config = {
                withCredentials: true,
                headers: {
                  'Content-Type': 'application/json',
                },
              };
            const {data} = await axios.post(`${apiUrl}/login`, logUserData, config)
            console.log(data.type === "success")
            

            const redirectedPath = location.state?.path || '/'
            if(data.type === "success"){
                auth.logIn(data.data)
                dispatch({type:FLASH_SUCCESS, payload:data.message})
            }
            else if(data.type === "error"){
                console.log("happened")
                dispatch({type:FLASH_ERROR, payload:data.message})
            }
        
            navigate(redirectedPath, {replace: true})

        }
        catch(err){
            console.log(err.message)
        }
    }
  return (
    <div className='login-page'>
        <div className='login-header'/>
        <div className='logic-section'>
            <form onSubmit={handleSubmit} className='d-flex align-items-center justify-content-center'>
                {/* <div > */}
                    <div className='d-flex flex-column justify-content-start align-items-center' style={{height:"90vh", paddingTop:"120px"}}>
                        <div className='d-flex flex-column justify-content-between align-items-start'>
                            <div class=" my-2">
                                <div class="form-group has-placeholder">
                                    <div className='login-field-label'>Username</div>
                                    <input type="text" className='login-field-input'  size="100" value={logUserData.username} onChange={(e) => setlogUserData({...logUserData, username: e.target.value})} name="username"  placeholder="Enter Username" />
                                </div>
                            </div>
                            <div class=" my-2">
                                <div class="form-group has-placeholder">
                                    <div  className='login-field-label'>Password</div>                    
                                    <input type="password" className='login-field-input'  size="100" value={logUserData.password} onChange={(e) => setlogUserData({...logUserData, password: e.target.value})} name="password"  placeholder="Enter Password" />
                                </div>
                            </div>
                            <div style={{width:"100%"}} class="d-flex align-items-center justify-content-between my-3">
                                <button className="login-btn" type="submit">Login</button>
                                <Link className='register-link' to='/register'>Register</Link>
                            </div>
                        </div>
                        {/* <div class="col-lg-6 my-2">
                            <div class="form-group">
                                New User?<Link to="/register" style={{color:"blue"}}>Sign Up</Link>
                            </div>
                        </div> */}
                    </div>
                {/* </div> */}
                
            </form> 
        </div>
        <img src="images/casewell-login-footer.png" className='login-footer'/>
    </div>
  )
}

export default Login