import React from 'react'
import '../css/stopCollar.css'
function StopCollars() {
  return ( 
    <div> 
        <div className='d-flex flex-column flex-md-row' style={{height:"400px"}}>
            <div className=' stopCollar-banner-1'>
                <div>
                    <span>STOP COLLAR</span>
                </div>
                <div>
                    Stop collar keep centralizers and other casing attachments in place on the casing strings. These are available in hinged and slip-on styles.
                </div>
            </div>
            <div className='stopCollar-banner-2'>
                <img src="images/stop-collar-header-image.png" className='stop-collar-banner-img'/>
            </div>
        </div>
        <div className='d-flex flex-column'>   
            <div className='stop-collar-div'>
                <div className='row stop-collar-info'>
                    <div className='col-12 col-md-6 stop-collar-written-content' >
                        <div className='stop-collar-info-title'>
                            BOLTED
                        </div>
                        <div className='stop-collar-info-content'>
                            Bolted Stop Collar can be latched onto the casing pipe without having to slip on at the end of casing pipe. This allows for easy installation. The locking mechanism is at 180 degrees of the hinge.
                        </div>
                    </div>
                    <div className='col-12 col-md-6 stop-collar-item-column'>
                        <div className='d-flex align-items-center justify-content-center'>
                            <img src="images/bolted.svg" className='me-1 stop-collar-items-1'/>
                        </div>
                    </div>
                </div>  
            </div> 
            <div className='stop-collar-div'>
                <div className='row stop-collar-info'>
                    <div className='col-12 col-md-6 stop-collar-item-column'>
                        <img src="images/spiral-nail.svg" className='stop-collar-items-2'/>
                    </div>
                    <div className='col-12 col-md-6 stop-collar-written-content' >
                        <div className='stop-collar-info-title'>
                            SPIRAL NAIL
                        </div>
                        <div className='stop-collar-info-content'>
                            Spiral Nail Stop Collar is designed for larger flow rate bypass than can meet almost all technical requirements depending on specifications of the well.
                        </div>
                    </div>
                </div> 
            </div>
            <div className='stop-collar-div'>
                <div className='row stop-collar-info'>
                    <div className='col-12 col-md-6 stop-collar-written-content' >
                        <div className='stop-collar-info-title'>
                            HINGED SET SCREW
                        </div>
                        <div className='stop-collar-info-content'>
                            Hinged Set Screw Stop Collars can be installed very easily on to the casing. They are most effective in conditions of low annual clearance. The set screws give added holding power to the stop collar.
                        </div>
                    </div>
                    <div className='col-12 col-md-6 stop-collar-item-column'>
                        <img src="images/hinged-set-crew.svg" className='me-1 stop-collar-items-3'/>
                    </div>
                </div>    
            </div>
            <div className='stop-collar-div' style={{marginBottom:"20px"}}>
                <div className='row stop-collar-info' style={{borderBottom:"0"}}>
                    <div className='col-12 col-md-6 stop-collar-item-column'>
                        <img src="images/slip-on-set-crew.svg" className='me-1 stop-collar-items-4'/>
                    </div>
                    <div className='col-12 col-md-6 stop-collar-written-content' >
                        <div className='stop-collar-info-title'>
                            SLIP ON SET SCREW
                        </div>
                        <div className='stop-collar-info-content'>
                            The slip on stop collar (often referred to as stop ring) with a single row of set screws, one side is beveled to help hold the tool in place.
                        </div>
                    </div>
                </div> 
            </div>     
        </div>
    </div>
  ) 
}

export default StopCollars