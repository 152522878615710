import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import RichTextEditor from './RichTextEditor';
import { useLocation, useParams } from 'react-router-dom';
import { updateBlog } from '../actions/blogs';
import { useNavigate } from 'react-router-dom'
import FileBase from 'react-file-base64'
import { useAuth } from '../utils/auth';
import '../css/CreateAndEditBlog.css'


function EditBlog() {

//   const location = useLocation()
//   const blogId = location.state.blog._id

    const {blogId} = useParams()
    const auth = useAuth()

    const blog = useSelector((state) => state.blogs).filter((blog) => {
        return blog._id === blogId
    })[0]

  const [blogBody, setBlogBody] = useState(blog.body)
  
  const [blogData, setBlogData] = useState({ title: blog.title, image: '',category:blog.category, summary:blog.summary, body: blogBody})
  const [formErrors, setFormErrors] = useState({})

  const dispatch = useDispatch();
  const navigate = useNavigate()
  
  const handleSubmit = async (e) => {
      e.preventDefault()
      setFormErrors(blogValidation(blogData))
  }

  const blogValidation = (blog) => {
    let errors = {}

    if(blog.title.length === 0){
        errors = {...errors, title:'Title is empty!'}
    }
    if(blog.image.length === 0){
        errors = {...errors, image:'Image  is not selected!'}
    }
    if(blog.category.length === 0){
        errors = {...errors, category:'Category is not selected!'}
    }
    if(blog.summary.length === 0){
        errors = {...errors, summary:'Summary is empty!'}
    }
    if(blog.body.length === 0){
        errors = {...errors, body:'Blog content is empty!'}
    }

    if(Object.keys(errors).length === 0){
        submitBlog()
    }
    
        return errors
    }

    const submitBlog = () => {
        const formData = new FormData();
    
        formData.append('title', blogData.title)
        formData.append('image', blogData.image)
        formData.append('category', blogData.category)
        formData.append('summary', blogData.summary)
        formData.append('body', blogData.body)
        formData.append('author', auth.loggedUser._id)
        formData.append('blogId', blogId)
        dispatch(updateBlog(formData))
  
      //   dispatch(updateBlog(blogId ,blogData))
        navigate('/blogs')
    }

  useEffect(() => {
    setBlogData({...blogData, body:blogBody})
    
}, [blogBody])

  return ( 
    <div className='blog-content mb-5' style={{overflow:"auto"}}>
    <div className='row d-flex justify-content-center'>
        <div className='col-md-9'>
                <div className='d-flex flex-column justify-content-start align-items-top m-5'  style={{height:"90vh"}}>
                    <div className='d-flex align-items-center' style={{background: "rgb(2,0,36)",
                            background: "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 0%, rgba(5,90,174,1) 0%, rgba(5,84,170,1) 0%, rgba(3,125,196,1) 0%, rgba(4,109,186,1) 0%, rgba(154,0,0,1) 0%, rgba(255,255,255,1) 100%)"}}>
                        <h2 className='m-1 ms-2' style={{color:"white"}}>
                            Edit Blog
                        </h2>
                    </div>
                    <form onSubmit={handleSubmit} enctype='multipart/form-data' validate>
                        <div className='row my-5  '>
                            <div className='col-3'>
                                Choose Banner: 
                            </div>
                            <div className="col-9 ">
                                <input type="file" onChange={(e) => setBlogData({...blogData, image: e.target.files[0]})} />
                                <div className="blog-form-error">{formErrors.image?formErrors.image:null}</div>  
                            </div>
                        </div>
                        <div className='row my-5'>
                            <div className='col-3'>
                                    Enter Title: 
                            </div>
                            <div className='col-9'>
                                <input class="form-control" type="text" value={blogData.title} placeholder='Enter Title' onChange={(e) => setBlogData({...blogData, title: e.target.value})} />
                                <div className="blog-form-error">{formErrors.title?formErrors.title:null}</div>  
                            </div>
    
                        </div>
                        <div className='row my-5'>
                            <div className='col-3'>
                                    Choose Category: 
                            </div>
                            <div className='col-9'>
                                <select value={blog.category} onChange={(e) => setBlogData({...blogData, category: e.target.value})} class="form-select" aria-label="Default select example">
                                    <option value="none" disabled>Open this select menu</option>
                                    <option value="Centralizer">Centralizer</option>
                                    <option value="Cementing">Cementing</option>
                                    <option value="Floats">Floats</option>
                                </select>
                                <div className="blog-form-error">{formErrors.category?formErrors.category:null}</div>
                            </div>
                        </div>
                        <div className='row my-5'>
                            <div className='col-3'>
                                    Write Summary: 
                            </div>
                            <div className='col-9'>
                                <textarea value={blogData.summary} className='form-control' placeholder='Write summary' onChange={(e) => setBlogData({...blogData, summary: e.target.value})} />
                                <div className="blog-form-error">{formErrors.summary?formErrors.summary:null}</div> 
                            </div>
                        </div>
                        <div className='my-3'>
                            <label className='mb-3'>
                                Edit Blog Content: 
                            </label>
                            <RichTextEditor className="editorPanel" initialValue={blog.body} setBlogBody={setBlogBody}/>
                            <div className="blog-form-error">{formErrors.body?formErrors.body:null}</div>  
                        </div>
                        <div className='my-5'>
                            <button class="submit-btn" type="submit">Submit</button>
                        </div>
                    </form>
            </div>
        </div>
    </div>
</div>





    
  )
}

export default EditBlog