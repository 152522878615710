import React, {useEffect} from 'react'
import '../css/adminBlogs.css'
import { useSelector, useDispatch } from 'react-redux'
import { useAuth } from '../utils/auth'
import { getBlogs } from '../actions/blogs'
import { Spinner } from './Spinner'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { deleteBlog } from '../actions/blogs'

function AdminBlogs() {

    const navigate = useNavigate()
    const dispatch = useDispatch()
	const auth = useAuth()
	const allBlogs = useSelector((state) => state.blogs)

    const handleDelete = async ( blog) => {
        dispatch(deleteBlog(blog))
    }

    useEffect(() => {
		dispatch(getBlogs())
	}, [dispatch])

  return (
    <div className='adminblogscontainer'>
        <div className='row mx-0'>
            <div className='col-1 blogs-headings'>S.No.</div>
            <div className='col-3 blogs-headings'>Blog Name</div>
            <div className='col-2 blogs-headings'>Author</div>
            <div className='col-2 blogs-headings'>Publish Date</div>
            <div className='col-2 blogs-headings'>Edit</div>
            <div className='col-2 blogs-headings' style={{borderRight:"0"}}>Delete</div>
        </div>
        {   
            allBlogs.length?
            allBlogs.slice(0).reverse().map((blog, index) =>{

                const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
                const str = blog.date;
                const date = new Date(str)
                return <div className='row mx-0'>
                            <div className='col-1 blog-info'>{index+1}</div>
                            <div className='col-3 blog-info'>{blog.title.substring(0,25)}...</div>
                            <div className='col-2 blog-info'>{blog.username}</div>
                            <div className='col-2 blog-info'>{`${date.getDate()} ${month[date.getMonth()]}, ${date.getFullYear()}`}</div>
                            <div className='col-2 blog-info'>
                                <Link  to={`/blogs/editBlogs/${blog._id}`} className={`blog-btn blog-pst-btn`}>Edit</Link>
                            </div>
                            <div className='col-2 blog-info' style={{borderRight:"0"}}>
                                <button onClick={() => handleDelete(blog)} className='blog-btn blog-ngt-btn'>Delete</button>
                            </div>
                        </div>  
                
            }):<div className='d-flex justify-content-center al my-5' style={{height:`662.26px`, paddingTop:"250px"}}><Spinner /></div> 
        }
    </div>
  )
}

export default AdminBlogs