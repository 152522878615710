import React from 'react'
import { Link } from 'react-router-dom'
import '../css/products.css'

function Products() {
  return (
    <div className='products-page'>
        <div className=' products-banner'>
            <div className='d-flex flex-column align-items-center justify-content-center '>
                <div className='products-banner-title'><span>OUR PRODUCTS</span></div>
                <div className='products-banner-text'>Over 5000 products consisting of Centralizers, Float Equipment, and other Cementing products</div>
            </div>

        </div>
        <div className='d-flex flex-column pt-3'>
            <Link to='/centralizers' className='products-links'>
                <div className='row products-section d-flex flex-row '>
                    <div className='col-12 col-md-6 d-flex justify-content-center align-items-center'>
                        <iframe width="524.44" height="295" src="https://www.youtube.com/embed/93ONUL3S_7w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    <div className='col-12 col-md-6 d-flex flex-column products-first-section' >
                        <div className='products-section-title pt-5'>
                            CENTRALIZERS 
                        </div>
                        <div className='products-section-info'>
                            Designed with high restoring force and low starting force to keep casing in the center of the well bore.
                        </div>
                    </div>
                </div> 
            </Link>
            <div className='row products-section-2' style={{margin:"0 !important"}}>
                <div className='col-12 col-md-4 mt-3 px-0 pe-md-2'>
                    <Link to='/floats' className='products-links'>
                        <div className='products-section products-section-bottom'>
                            <div className=' d-flex flex-column '>
                                <div className='products-section-title'>
                                    FLOATS
                                </div>
                                <div className='products-section-info'>
                                    Used to control the "U tube" effect of fluids down hole.                          
                                </div>
                            </div>
                            <div className=' d-flex justify-content-center justify-content-md-end align-items-center'>
                                <img src="images/products-section-item-1.png"/>
                            </div>
                        </div>
                    </Link>
                </div> 
                <div className='col-12 col-md-4 my-3 px-0 px-md-2'>
                    <Link to='/stopCollars' className='products-links'>
                        <div className='products-section products-section-bottom'>
                            <div className=' d-flex flex-column '>
                                <div className='products-section-title'>
                                    STOP COLLAR
                                </div>
                                <div className='products-section-info'>
                                    Used to isolate a centralizer or other casing attachment to a specific place on the casing string.                            
                                </div>
                            </div>
                            <div className='d-flex justify-content-center justify-content-md-end align-items-center'>
                            {/* <img src="images/products-section-item-2.png"/> */}
                            <img src="images/stop-collar-header-image.png"/>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className='col-12 col-md-4 mt-3 px-0 ps-md-2 mb-3 mb-md-0'>
                    <div className='products-section products-section-bottom'>
                        <div className='d-flex flex-column '>
                            <div className='products-section-title'>
                                ACCESSORIES
                            </div>
                            <div className='products-section-info'>
                                Used to smoothen and simplify the entire casing & cementing process.  These lead to to a better bonding for casing & cementing in the deep surface.
                            </div>
                        </div>
                        <div className='d-flex justify-content-center justify-content-md-end align-items-center'>
                            <img src="images/products-section-item-3.png"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Products