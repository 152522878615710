import Blog from "./components/Blog";
import Header from './components/Header'
import Footer from './components/Footer'
import Register from "./components/Register";
import Login from "./components/Login";
import CreateBlog from "./components/CreateBlog";
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import BlogDetail from "./components/BlogDetail";
import { Authprovider, useAuth } from "./utils/auth";
import { RequireAuth } from "./utils/RequireAuth";
import EditBlog from "./components/EditBlog";
import NotFound from "./components/NotFound";
import UserBlogs from "./components/UserBlogs";
import AdminUsers from "./components/AdminUsers";
import Home from "./components/Home";
import Flash from "./components/Flash";
import VerifyEmail from "./components/VerifyEmail";
import AboutUs from "./components/AboutUs";
import BlogNew from "./components/BlogNew";
import BlogDetailNew from "./components/BlogDetailNew";
import { useEffect } from "react";
import axios from "axios";
import { apiUrl } from "./api";
import Leadership from "./components/Leadership";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Journey from "./components/Journey";
import Capabilities from "./components/Capabilities";
import ContactUs from "./components/ContactUs";
import StopCollars from "./components/StopCollars";
import Floats from "./components/Floats";
import Centralizer from "./components/Centralizer";
import Csr from "./components/Csr";
import Products from "./components/Products";
import Quality from "./components/Quality";
import Accessories from "./components/Accessories";
import ScrollToTop from "./components/ScrollToTop";
import AdminBlogs from "./components/AdminBlogs";
import AdminContacts from "./components/AdminContacts";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {

  return (
    <div className="App ">
      <Router>
      <ScrollToTop />
        <Authprovider>
         
          <Header />
          <Flash />
          {/* <div className="d-flex justify-content-center" style={{width:"1280px"}}> */}

            <Routes>
              
                <Route path='/' element={<Home />} />


                <Route path='register' element={<Register />} />
                <Route path='login' element={<Login />} />

                <Route path='aboutus' element={<AboutUs />} />

                <Route path='/blogs' element={<BlogNew />} />
                <Route path='blogs/editBlogs/:blogId' element={<RequireAuth><EditBlog /></RequireAuth>} />
                <Route path='blogs/createblog' element={<RequireAuth><CreateBlog /></RequireAuth>} />
                <Route path='userblogs' element={<RequireAuth><UserBlogs /></RequireAuth>} />
                <Route path='verifyemail' element={<VerifyEmail />} />
                <Route path='blogs/details/:blogId' element={<BlogDetailNew />} />

                <Route path='privacypolicy' element={<PrivacyPolicy />} />
                <Route path='journey' element={<Journey />} />
                <Route path='capabilities' element={<Capabilities />} />
                <Route path='contactus' element={<ContactUs />} />
                <Route path='stopcollars' element={<StopCollars/>} />
                <Route path='floats' element={<Floats/>} />
                <Route path='centralizers' element={<Centralizer/>} />
                <Route path='csr' element={<Csr/>} />
                <Route path='products' element={<Products/>} />
                <Route path='quality' element={<Quality/>} />
                <Route path='aboutus' element={<AboutUs/>} />
                <Route path='accessories' element={<Accessories/>} />

                <Route path='admin/users' element={<RequireAuth><AdminUsers /></RequireAuth>} />
                <Route path='admin/blogs' element={<RequireAuth><AdminBlogs /></RequireAuth>} />
                <Route path='admin/contacts' element={<RequireAuth><AdminContacts /></RequireAuth>} />

                <Route path='/*' element={<NotFound/>} />
              
            </Routes>
          {/* </div> */}
          <Footer />
        </Authprovider>
      </Router>
    </div>
  );
}

export default App;
