import React from 'react'
import '../css/footer.css'
import styled from 'styled-components'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import {Link} from 'react-router-dom'
 
function Footer() {
  return (
        <div className='footer-section'>
          <div className='d-flex flex-wrap justify-content-between'>
            <div className=' footer-column-container ' style={{width:"250px"}}>
              <div className='footer-columns'>
                <img className='mb-3' src="images/casewell-whitelogo.svg" style={{height:"60px", width:"200px"}}/>   
                <div>
                  <div>
                    Global Leader in Cementing Solutions 
                    for Oil & Gas Industry
                  </div>
                  <div>
                    Sector 7, IMT Manesar, Gurgaon, India
                  </div> 
                </div>      
              </div>
            </div> 
            <div className='footer-column-container '>
              <div className=' footer-columns'>
                <Link to='/products' className='footer-column-headings'><span className='footer-headings-border' >Products</span></Link>
                <Link to='/centralizers'><div>Centralizer</div></Link>
                <Link to='/floats'><div>Floats</div></Link>
                <Link to='/stopcollars'><div>Stop Collars</div></Link>
                <Link to='/accessories'><div>Accessories</div></Link>
              </div>
            </div>
            <div className='footer-column-container '>
              <div className=' footer-columns '>
                <Link className='footer-column-headings'><span className='footer-headings-border'>Company</span></Link>
                <Link to='/journey'><div>Journey</div></Link>
                <Link to='/capabilities'><div>Capabilities</div></Link>
                <Link to='/quality'><div>Quality</div></Link>
                <Link to='/blogs'><div>News</div></Link>
              </div>
            </div>
            <div className='footer-column-container'>
              <div className='footer-columns'>
                <Link to='/contactus' className='footer-column-headings'><span className='footer-headings-border' >Contact Us</span></Link>
                <div><Phone />+91-124-4271032</div>
                <div className='d-flex'><Email/>sales@casewell.in</div>
                <div><a href='https://www.linkedin.com/company/casewellin/'><LinkedIn /></a> <a href='https://twitter.com/casewellin'><Twitter /></a></div>
              </div>
            </div> 
          </div>
          {/* <div className='row'>
            <div className='col-12 col-md-3 ps-lg-0  footer-column-container '>
              <div className='footer-columns'>
                <img className='mb-3' src="images/casewell-logo-white.png" style={{height:"60px", width:"200px"}}/>   
                <div style={{paddingLeft:"6px"}}>
                  <div>
                    Global Leader in Cementing Solutions 
                    for Oil & Gas Industry
                  </div>
                  <div>
                    Sector 7, IMT Manesar, Gurgaon, India
                  </div> 
                </div>      
              </div>
            </div> 
            <div className='col-12 col-md-3 footer-column-container '>
              <div className=' footer-columns'>
                <Link to='/products' className='footer-column-headings'><span className='footer-headings-border' >Products</span></Link>
                <Link to='/centralizers'><div>Centralizer</div></Link>
                <Link to='/floats'><div>Floats</div></Link>
                <Link to='/stopcollars'><div>Stop Collars</div></Link>
                <Link to='/accessories'><div>Accessories</div></Link>
              </div>
            </div>
            <div className='col-12 col-md-3 footer-column-container '>
              <div className=' footer-columns '>
                <Link className='footer-column-headings'><span className='footer-headings-border'>Company</span></Link>
                <Link to='/journey'><div>Journey</div></Link>
                <Link to='/capabilities'><div>Capabilities</div></Link>
                <Link to='/quality'><div>Quality</div></Link>
                <Link to='/blogs'><div>News</div></Link>
              </div>
            </div>
            <div className='col-12 col-md-3  ps-lg-0 footer-column-container'>
              <div className='footer-columns'>
                <Link to='/contactus' className='footer-column-headings'><span className='footer-headings-border' >Contact Us</span></Link>
                <div><Phone />+91-8826161114</div>
                <div className='d-flex'><Email/>sales@casewell.in</div>
                <div><a href='https://www.linkedin.com/company/casewellin/'><LinkedIn /></a> <a href='https://twitter.com/casewellin'><Twitter /></a></div>
              </div>
            </div> 
          </div> */}
        </div>
  )
}

const LinkedIn = styled(LinkedInIcon)`
  color: white;
  cursor:pointer;
`

const Twitter = styled(TwitterIcon)`
  color: white;
  margin-left: 10px;
  cursor:pointer;
`

const Email = styled(EmailIcon)`
  color: white;
  cursor:pointer;
  margin-right:5px;
`
const Phone = styled(LocalPhoneIcon)`
  color: white;
  margin-right:5px;
`

export default Footer