import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { apiUrl } from '../api'
import { Spinner } from './Spinner'
import '../css/adminContacts.css'
function AdminContacts() {

    const [contacts, setContacts] = useState([])
    const [contactIndex, setContactIndex] = useState(0)
    const [flag, setFlag] = useState(true)

    const getContacts = async (e) => {
      const config = {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
        },
      };
        const {data} = await axios.get(`${apiUrl}/getContacts/${contactIndex}`,config) 
        setContacts([...contacts,...data])
        setContactIndex(contactIndex+20)
        console.log(contactIndex)
    }

    const loadMore = () => {
      getContacts()
    }

    useEffect(() => {
      if(contacts.length === 0)
        getContacts()

      if(contacts.length<contactIndex){
        setFlag(false)
      }
    })

  return (
    <div className='admincontactcontainer' >
        <div className='row m-0 d-flex justify-content-center' >
            <div className='col-1 contact-headings'>
                S.No.
            </div>
            <div className='col-1 contact-headings'>
                First Name
            </div>
            <div className='col-1 contact-headings'>
                Last Name
            </div>
            <div className='col-1 contact-headings'>
                Company
            </div>
            <div className='col-2 contact-headings'>
                Email
            </div>
            <div className='col-1 contact-headings'>
                Phone
            </div>
            <div className='col-1 contact-headings'>
                Country
            </div>
            <div className='col-3 contact-headings' style={{borderRight:"0"}}>
                Message
            </div>
        </div>
        {
            contacts.length?
            contacts.map((contact, index) => {
                return(
                    <div className='row mx-0 d-flex justify-content-center' >
                      <div className='col-1 contact-info'>
                        {index+1}
                      </div>
                      <div className='col-1 contact-info'>
                        {contact.firstName}
                      </div>
                      <div className='col-1 contact-info'>
                        {contact.lastName}
                      </div>
                      <div className='col-1 contact-info'>
                        {contact.company}
                      </div>
                      <div className='col-2 contact-info'>
                        {contact.email}
                      </div>
                      <div className='col-1 contact-info'>
                        {contact.phone}
                      </div>
                      <div className='col-1 contact-info'>
                        {contact.country}
                      </div>
                      <div className={`col-3 contact-info ${contact.message.length>20?'message':null}`} style={{borderRight:"0"}}>
                        {contact.message}
                      </div>
                    </div>
                )}):<div className='d-flex justify-content-center al my-5' style={{height:`662.26px`, paddingTop:"250px"}}><Spinner /></div>
        }
      {
        flag?
            <div className='d-flex justify-content-center ' style={{cursor:"pointer", margin:"80px 0 30px"}}>
                <div onClick={loadMore} id="load-more">
                    <div>Load More</div>
                </div>
            </div>
         :<div className='d-flex justify-content-center' style={{margin:"30px 0"}}>--------------------End of Page--------------------</div>
        } 
      
    </div>
  )
}

export default AdminContacts