import React from 'react'
import '../css/quality.css'
function Quality() {
  return ( 
    <div className='quality-page'>
        <div className='d-flex align-items-center justify-content-center justify-content-md-start quality-banner-1'>
            <div className='quality-banner-1-content'>
                <div className='quality-banner-1-content-title'>
                    <span>QUALITY MANAGEMENT SYSTEM</span>
                </div>  
                <div className='quality-banner-1-content-text'>
                    Intricately engineered and globally certified production process to achieve success in any cementing job.
                </div>
            </div>
        </div> 
        <div className='quality-section'>
            <div className='quality-section-div'>
                <div className='row quality-info'>
                    <div className='col-12 col-md-6 quality-section-content'>
                        <div>International Quality Standards</div>
                        <div>Our quality management system is designed as per API specification Q1, ISO 9001-2015, EMS 6 and OSHA 14001.</div>
                    </div>
                    <div className='col-12 col-md-6 d-flex justify-content-center align-items-center'>
                        <div className='quality-section-content-item'>
                            <img src='images/quality-item-1-new.png' />
                        </div>
                    </div>
                </div> 
            </div>
            <div className='quality-section-div'>
                <div className='row quality-info'>
                    <div className='col-12 col-md-6 d-flex justify-content-center align-items-center'>
                        <div className='quality-section-content-item'>
                            <img className='quality-banner-2-item' src='images/quality-banner-2-item.png' />
                        </div>
                    </div>
                    <div className='col-12 col-md-6 quality-section-content'>
                        <div>Quality Systems</div>
                        <div>Quality Control Function is focused on fulling quality requirements to ensure that the final product adheres to defined specifications. Extensive checklists & SOPs are in place across the operations to ensure the manufacturing of highest quality products.</div>
                    </div>
                </div>
            </div>
            <div className='quality-section-div' style={{marginBottom:"20px"}}>
                <div className='row  quality-info'>
                    <div className='col-12 col-md-6 quality-section-content'>
                        <div>Advanced Testing Facility</div>
                        <div>We operate advanced electronically controlled heat-treating conveyor lines and testing facility. Our Loop Testing Facility ensures quality and efficiency of the highest order.</div>
                    </div>
                    <div className='col-12 col-md-6 d-flex justify-content-center align-items-center'>
                        <div className='quality-section-content-item'>
                            <img src='images/quality-item-2-new.png' />
                        </div>
                    </div>
                </div>
            </div>

            </div>
    </div>
  )
}

export default Quality