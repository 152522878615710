import {CREATE_BLOG,
    LOAD_MORE,
    UPDATE_BLOG,
    DELETE_BLOG,
    FETCH_ALL_BLOGS,
FLASH_SUCCESS,
FLASH_ERROR
} from '../constants/actionTypes'
import axios from 'axios';
import { apiUrl } from '../api';

const config = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  }; 

export const getBlogs = (index) => async(dispatch) =>  {
    try{
        const {data} = await axios.get(`${apiUrl}/blogs/${index}`, config)
        if(index==0){
            dispatch({type: FETCH_ALL_BLOGS, payload: data})
        }
        else{
            dispatch({type: LOAD_MORE, payload: data})        
        }
    }
    catch(err){
        console.log(err.message)
    }
}




export const createBlog = (blogData) => async (dispatch) => {
    try{
        const configtwo = {
            withCredentials: true,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          };
        const {data} = await axios.post(`${apiUrl}/blogs/createBlog`, blogData, configtwo)
        
        dispatch({type:CREATE_BLOG, payload: data.data})

        if(data.type === "success"){
            dispatch({type:FLASH_SUCCESS, payload:data.message})
        }
        else
            dispatch({type:FLASH_ERROR, payload:data.message})
    }
    catch(err){
        dispatch({type:FLASH_ERROR, payload:err.message})
    }
}

export const updateBlog = (blogData) => async (dispatch) => {
    try{
        const configtwo = {
            withCredentials: true,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          };
        const {data} = await axios.post(`${apiUrl}/blogs/editBlog`,blogData, configtwo)
        dispatch({type:UPDATE_BLOG, payload: data.data})

        if(data.type === "success"){
            dispatch({type:FLASH_SUCCESS, payload:data.message})
        }
        else
            dispatch({type:FLASH_ERROR, payload:data.message})
    }
    catch(err){
        dispatch({type:FLASH_ERROR, payload:err.message})
    }
}


export const deleteBlog = (blog) => async (dispatch) => {
    try{

        const {data} = await axios.post(`${apiUrl}/blogs/deleteBlog`, {blog}, config)
        dispatch({type:DELETE_BLOG, payload: blog._id})

        if(data.type === "success"){
            dispatch({type:FLASH_SUCCESS, payload:data.message})
        }
        else
            dispatch({type:FLASH_ERROR, payload:data.message})
    }
    catch(err){
        dispatch({type:FLASH_ERROR, payload:err.message})
    }
}
