import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useAuth } from '../utils/auth'
import { Navigate } from 'react-router-dom'
import { FLASH_SUCCESS, FLASH_ERROR } from '../constants/actionTypes'
import { useDispatch } from 'react-redux'
import { apiUrl } from '../api';
import '../css/header.css'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
 

function Header() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const auth = useAuth()

    const handleLogout = async () => {
        const config = {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            },
          };

        const {data} = await axios.get(`${apiUrl}/logout`, config)
        
        if(data.type === 'success')
        auth.logOut()

        if(data.type === "success"){
            dispatch({type:FLASH_SUCCESS, payload:data.message})
        }
        else
            dispatch({type:FLASH_ERROR, payload:data.message})

        navigate('/', {replace: true})
    }
 

  return ( 
    <header class="header-section">
        <nav class="navbar navbar-expand-lg header-nav navbar-case" style={{ padding:"0"}}>
            <div class="container-fluid" style={{padding:"0"}}>
                <Link className='my-3' to='/'>
                    <a class="navbar-brand" href="#">
                        <img src="images/casewell-logo.svg" style={{height:"26px"}} alt="" />
                    </a>
                </Link>
                <button class="navbar-toggler my-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse d-lg-flex justify-content-lg-end align-items-center" id="navbarSupportedContent">
                    <div class="navbar-nav flex-row justify-content-start justify-content-lg-end flex-wrap" >
                        <div class="d-flex justify-content-xl-center align-items-center nav-links-box">
                            <Link to='/'  className='header-links '><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Home</div></Link>
                        </div>
                        <div class="d-flex flex-column align-items-xl-center justify-content-center nav-links-box header-dropdown-column">
                            <input type="checkbox" style={{display:"none"}} id='note-title-label-check-1'/>
                            <Link to='/aboutus' className='header-links'><div >About Us</div><ArrowDropDownIcon /></Link>
                            <div className='d-flex flex-column header-dropdown' >
                                <div className='header-drop-down-blur'/>
                                <Link to='/journey'><div data-bs-toggle="collapse"data-bs-target=".navbar-collapse.show">Journey</div></Link>
                                <Link to='/capabilities'><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" style={{borderBottom:"0"}}>Capabilities</div></Link>
                            </div>
                        </div> 
                        <div class="d-flex flex-column align-items-xl-center justify-content-center nav-links-box header-dropdown-column">
                            <input type="checkbox" style={{display:"none"}} id='note-title-label-check-2' />
                            <Link to='/products' className='header-links'><div>Products<ArrowDropDownIcon /></div></Link>
                            <div className='d-flex flex-column header-dropdown'>
                                <div className='header-drop-down-blur'/>
                                <Link to='/centralizers'><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Centralizers</div></Link>
                                <Link to='/floats'><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Floats</div></Link>
                                <Link to='/stopcollars'><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Stop Collars</div></Link>
                                <Link to='/accessories'><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" style={{borderBottom:"0"}}>Accessories</div></Link>
                            </div>
                        </div>
                        <div class="d-flex justify-content-xl-center align-items-center nav-links-box">
                            <Link to='/quality' className='header-links'><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Quality</div></Link>
                        </div>   
                        <div class="d-flex justify-content-xl-center align-items-center nav-links-box ">
                            <Link to='/csr' className='header-links '><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">CSR</div></Link>
                        </div>
                        <div class="d-flex justify-content-xl-center align-items-center nav-links-box ">
                            <Link to='/blogs' className='header-links'><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">News</div></Link>
                        </div>
                        <div class="d-flex justify-content-xl-center align-items-center nav-links-box ">
                            <Link to="/contactus" className='header-links'><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Contact</div></Link>
                        </div>
                        {       
                        
                            (auth.loggedUser&& (process.env.REACT_APP_ADMIN  === auth.loggedUser._id))?
                            <div class="d-flex flex-column align-items-xl-center justify-content-center nav-links-box header-dropdown-column">
                                <input type="checkbox" style={{display:"none"}} id='note-title-label-check-2' />
                                <Link className='header-links'><div >Admin<ArrowDropDownIcon /></div></Link>
                                <div className='d-flex flex-column header-dropdown'>
                                    <div className='header-drop-down-blur'/>
                                    <Link to='/admin/blogs'><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Blogs</div></Link>
                                    <Link to='/admin/users'><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Users</div></Link>
                                    <Link to='/admin/contacts'><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" style={{borderBottom:"0"}}>Contacts</div></Link>
                                </div>
                            </div>
                            :null 
                        }

                        {
                        auth.loggedUser?
                            <>
                                <div class="d-flex flex-column align-items-xl-center justify-content-center nav-links-box header-dropdown-column">
                                    <input type="checkbox" style={{display:"none"}} id='note-title-label-check-2' />
                                    <Link  className='header-links'><div>Blogs<ArrowDropDownIcon /></div></Link>
                                    <div className='d-flex flex-column header-dropdown'>
                                        <div className='header-drop-down-blur'/>
                                        <Link to="/userblogs"><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">My Blogs</div></Link>
                                        <Link to='/blogs/createblog'><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show" style={{borderBottom:"0"}}>Create Blog</div></Link>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-xl-center align-items-center nav-links-box ">
                                    <a href="#" className='header-links header-links-2' onClick={handleLogout}><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Logout</div></a>
                                </div>
                            </>
                            
                            :
                            <div class="d-flex justify-content-xl-center align-items-center nav-links-box ">
                                <Link to="/login" className='header-links header-links-2'><div data-bs-toggle="collapse" data-bs-target=".navbar-collapse.show">Login</div></Link>
                            </div>
                        }

                       
                    </div>
                </div>
            </div>
        </nav>
    </header>
  )
}

export default Header