import React from 'react'
import '../css/Journey.css'
function OurJourney() { 
  return ( 
    <div> 
        <div className='ourJourney-banner'>
            <div>OUR JOURNEY</div>
        </div>
        <div style={{marginBottom:"15px"}}>
          <div className='row journey-section'>
              {/* <div className='col-12 col-md-3 d-flex  px-0 pe-md-2 journey-column-container'> */}
              <div className='col-12 col-md-3 ps-0 d-flex  journey-column-container'>
                <div className='journey-columns journey-column-1'>
                  <img src='images/journey-col-1-item-1.png' className='mt-3 mb-2 journey-col-1-item-1' />
                  <div className='journey-sub-headings mb-5'><span>Build (2000-2006)</span></div>
                  <div className='d-flex justify-content-center'>
                    <div className='journey-text pb-5'>
                      Founders came together with a vision to build an organization that will be a discerning producer of durable and reliable tools for Oil and Gas industry.
                    </div>
                  </div>
                  <img src='images/journey-col-1-item-2.png'  className='journey-col-1-item-2' />
                  <div className='d-flex justify-content-center'>
                    <div className='journey-text pb-3'>
                      Set up first manufacturing unit in the industrial hub of Gurgaon, India.
                    </div>
                  </div>
                  <img src='images/journey-col-footer-1.png' className='journey-col-footer'/>
                </div>
              </div>
              {/* <div className='col-12 col-md-3 px-0 px-md-2 d-flex journey-column-container'> */}
              <div className='col-12 col-md-3 d-flex journey-column-container'>
                <div className='journey-columns journey-column-2'>
                  <div className='journey-sub-headings mt-3'><span>Innovate (2007-2013)</span></div>
                  <img src='images/journey-col-2-item-1.png' style={{height:"105px", margin:"20px 0px 30px 30px"}}/>
                  <div className='journey-text pb-3'>
                    Developed innovative & patentable designs for Dualizer-Centralizer, Float Equipment & One-Piece Super Centralizer.
                  </div>
                  <img src='images/journey-col-2-item-2.png' style={{height:"70px", margin:"20px 0px"}}/>
                  <div className='journey-text pb-3'>
                    Received awards for Occidental contracts in Oman, Qatar Petroleum and Reliance contract for Block D-5 in India.
                  </div>
                  <img src='images/journey-col-2-item-3.png' style={{height:"60px", margin:"20px 0px"}}/>
                  <div className='journey-text pb-3 px-2'>
                    Entered in strategic agreement with Global Top 3 Oilfield Service company for supplies in APAC and Europe.
                  </div>
                  <div style={{position:"relative"}}>
                    <img src='images/journey-col-2-item-4.png' style={{height:"30px", height: "37px", position: "absolute", top: "43px", left: "-6px"}}/>
                    <img src='images/journey-col-2-item-3.png' style={{height:"60px", margin:"20px 0px"}}/>
                  </div>
                  <div className='journey-text pb-3 px-2' style={{marginBottom:"35px", border:"0"}}>
                    ·Got audited & approved by Top 5 Global O&G company                    
                  </div>
                  <img src='images/journey-col-footer-2.png' className='journey-col-footer' style={{width:"116%"}}/>
                </div>
              </div>
              {/* <div className='col-12 col-md-3 px-0 px-md-2 d-flex journey-column-container'> */}
              <div className='col-12 col-md-3 d-flex journey-column-container'>
                <div className='journey-columns journey-column-3'>
                  <img src='images/journey-col-3-item-1.png' className='mt-3 journey-col-3-item-1'/>
                  <div className='journey-sub-headings my-5'><span>Automate (2014-2017)</span></div>
                  <div className='journey-text pb-5'>
                    Built new plant using sophisticated technology enhancing production capacity by 5X, making us largest single unit manufacturer in Asia-Pacific.                      
                  </div>
                  <img src='images/journey-col-3-item-2.png' className='mt-4' style={{height:"135px"}}/>
                  <div className='journey-text mt-5 pb-5'>
                    Introduced automated heat treatment, robotic welding, SPM, CNC machines and state of art acrylic CED paint facility.
                  </div>
                  <img src='images/journey-col-footer-1.png' className='journey-col-footer'/>
                </div>
              </div>
              {/* <div className='col-12 col-md-3 d-flex px-0 ps-md-2 journey-column-container'> */}
              <div className='col-12 col-md-3 d-flex pe-0 journey-column-container'>
                <div className='journey-columns journey-column-4' >
                  <div className='journey-sub-headings mt-4'><span>Expand (2018 Onwards)</span></div>
                  <img src='images/journey-col-4-item-1.png' style={{height:"170px", marginRight:"35px"}}/>
                  <div className='journey-text mt-4 pb-5'>
                    Built tie ups and started supplies in Middle East, USA, Canada & Latin America
                  </div>
                  <img src='images/journey-col-4-item-2.png' className='mt-4'  style={{height:"70px"}}/>
                  <div className='journey-text mt-4 pb-5'>
                    ·Awarded contracts for Sonatrach-Algeria, Santos-Australia Sinopec & PAE-Argentina, Petrobras-Brazil.
                  </div>
                  <div style={{position:"relative"}}>
                    <img src='images/journey-col-2-item-3.png' style={{height: "50px", position: "absolute", top: "20px", left: "-10px"}}/>
                    <img src='images/journey-col-4-item-3.png' style={{height:"40px", margin:"40px 0 0 40px"}}/>
                  </div>                    
                  <div className='journey-text mt-4 pb-5'>
                    Entered into global supply agreements with multiple Top 5 Oilfield Service companies                    
                  </div>
                  <img src='images/journey-col-footer-2.png' className='journey-col-footer' style={{width:"116%"}}/>

                </div>
              </div>           
          </div>
        </div>
    </div>
  )
}

export default OurJourney