import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { deleteBlog } from '../actions/blogs';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../utils/auth';


function BlogDetail() {
    // const location = useLocation();
    // const blogId = location.state.id;
    
    const {blogId} = useParams() 
    const auth = useAuth()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const allBlogs = useSelector((state) => state.blogs)

    const blog = allBlogs.filter((blog) => blog._id === blogId)[0]
    const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    const str = blog.date;
    const date = new Date(str)

    const handleDelete = async (e) => {
      e.preventDefault()
      dispatch(deleteBlog(blog))
      navigate('/')
    }

  
    useEffect(() => {
        const blogContainer =  document.getElementById('blogContainer')
        blogContainer.innerHTML = blog.body

    })
    
  return (
    <>
        <section class="blog-detail-single">
            <div class="container">
              <div class="row ">
                      <div class="row d-flex  justify-content-center">
                        <div class="col-md-9">
                              <div class="blog-single">
                                <img src={blog.image} alt="" />
                                <div className='d-flex align-items-center justify-content-between '>
                                    <div>
                                          <h1>{blog.title}</h1>
                                        <ul>
                                          <li>23 May, 2022</li>
                                        <li>By {blog.username}</li>
                                        </ul>
                                    </div>
                    
                                            {
                              
                                                // (auth.loggedUser&&(auth.loggedUser._id === process.env.REACT_APP_ADMIN||(blog.user&&(auth.loggedUser._id === blog.user._id))))?
                                                (auth.loggedUser&&((auth.loggedUser._id === process.env.REACT_APP_ADMIN)||(blog.user?blog.user._id === auth.loggedUser._id: false)))?
                                                <div className="d-flex justify-content-end">
                                                  <Link to={`/blogs/editBlogs/${blogId}`} className='btn btn-info' state={{blog}}>Edit Blog</Link>

                                                  <form onSubmit={handleDelete}>
                                                      <button  className='ms-4 btn btn-danger' state={{id: blog._id}}>Delete Blog</button>
                                                  </form>
                                                </div>
                                              :null
                                          
                                            }
                                   
                                </div>
                            </div>
                            <div class="blog-single-content" >    
                                <div id="blogContainer"> </div>
                                  <div class="row d-flex mt-5 align-items justify-content-center">
                                    <div class="col-6 col-md-6 col-lg-6">
                                      <ul class="share-social">
                                        <li>Share:</li>
                                        <li><a href=""><i class="fab fa-facebook"></i></a></li>
                                        <li><a href=""><i class="fab fa-instagram"></i></a></li>
                                        <li><a href=""><i class="fab fa-twitter"></i></a></li>
                                      </ul>
                                    </div>  
                                        <div class="col-6 col-md-6 col-lg-6">
                                          <div class="next-prev">
                                          <a href=""><i class="fas fa-angle-left fa-fw"></i>Prev</a>
                                        <a href="">Next<i class="fas fa-angle-right fa-fw"></i></a>
                                        </div>
                                      </div> 
                                  </div>
                                  
                              </div>
                            
                    </div>
                      
                      
              
                
              
              </div>
                </div>	
          </div>
      </section>
 
 
 
      
      
  <section class="red-bg-seciton" style={{padding: "0px 0 0px 0"}}/>



    </>
  )
}

export default BlogDetail